/** @jsx jsx */

import { ComponentType, DragEvent, useCallback, useEffect, useRef } from 'react';

import { css, jsx } from '@emotion/core';

import { useDefaultFallbackStyling } from '../../controllers/default-styling';
import { useLoadingState } from '../../controllers/loading';

// https://stackoverflow.com/questions/26356877/html5-draggable-false-not-working-in-firefox-browser
const handleDragStart = (e: DragEvent<HTMLElement>) => e.preventDefault();

const loadingImageStyle = { opacity: 0 };

interface LoadingProps {
    src: string;
    width?: string | number;
    height?: string | number;
    draggable?: boolean;
    className?: string;
    setLoaded: (src: string) => void;
    setFailed: (src: string) => void;
}

const Loading = ({
    src,
    width,
    height,
    draggable = false,
    className,
    setLoaded,
    setFailed,
}: LoadingProps) => {
    const imgRef = useRef<HTMLImageElement>(null);

    const handleLoad = useCallback(() => setLoaded(src), [src]);
    const handleError = useCallback(() => setFailed(src), [src]);

    // handle images that are *already* loaded, e.g. because they have been SSR'ed before JS kicks in
    useEffect(() => {
        if (imgRef.current?.complete) {
            setLoaded(src);
        }
    }, [src]);

    return (
        <img
            src={src}
            alt=""
            width={width}
            height={height}
            draggable={draggable}
            onDragStart={draggable ? undefined : handleDragStart}
            onLoad={handleLoad}
            onError={handleError}
            className={className}
            style={loadingImageStyle}
            ref={imgRef}
        />
    );
};

const baseFallbackCss = css`
    display: inline-block;
`;

interface PropsWithoutFallback {
    src: string;
    alt: string;
    width?: string | number;
    height?: string | number;
    draggable?: boolean;
    className?: string;
}

const DefaultFallback = ({
    width,
    height,
    className,
}: Pick<PropsWithoutFallback, 'width' | 'height' | 'className'>) => {
    const [{ styles }] = useDefaultFallbackStyling();
    return (
        <div
            style={{ width: width ?? height, height: height ?? width }}
            css={[baseFallbackCss, styles]}
            className={className}
        />
    );
};

interface Props extends PropsWithoutFallback {
    Fallback?: ComponentType<Partial<PropsWithoutFallback>>;
}

export const ImgWithFallback = ({
    src,
    alt,
    width,
    height,
    draggable,
    className,
    Fallback = DefaultFallback,
}: Props) => {
    const [loadingState, { setLoaded, setFailed }] = useLoadingState(src);

    if (loadingState === 'loading') {
        return (
            <Loading
                src={src}
                width={width}
                height={height}
                draggable={draggable}
                className={className}
                setLoaded={setLoaded}
                setFailed={setFailed}
            />
        );
    }

    if (loadingState === 'loaded') {
        return (
            <img
                src={src}
                alt={alt}
                width={width}
                height={height}
                draggable={draggable}
                onDragStart={draggable ? undefined : handleDragStart}
                className={className}
            />
        );
    }

    return (
        <Fallback
            src={src}
            alt={alt}
            width={width}
            height={height}
            draggable={draggable}
            className={className}
        />
    );
};
