import { createHook, createStore, StoreActionApi } from 'react-sweet-state';

type State = {
    [src: string]: 'loading' | 'loaded' | 'failed';
};

type StoreAPI = StoreActionApi<State>;

const initialState: State = {};

const setLoaded = (src: string) => ({ setState }: StoreAPI) => {
    setState({ [src]: 'loaded' });
};

const setFailed = (src: string) => ({ setState }: StoreAPI) => {
    setState({ [src]: 'failed' });
};

const actions = {
    setLoaded,
    setFailed,
};

const Store = createStore<State, typeof actions>({
    initialState,
    actions,
    name: 'img-loading',
});

export const useLoadingState = createHook(Store, {
    selector: (state, src: string) => state[src] || 'loading',
});
